import React from 'react';
import appGlobalStore from "../store/appGlobalStore";
import appStateStore from "../store/appStateStore";
import {observer} from "mobx-react";



const PopUp = observer((props) => {
    const { dictionary } = appGlobalStore;
    const { popUpStatus, setPopUpStatus, language } = appStateStore;
    const { buttons, status } = popUpStatus;
    const closePopUp = () => {
        setPopUpStatus(null);
    }

    return (<>
            {popUpStatus && popUpStatus.status && <div className="popUpBackShadow">
                <div className="popUpInner">
                    {dictionary[language][status]}
                    {buttons && buttons.length !== 0 &&
                      buttons.map((button, index) => {
                          return <button key={index} onClick={() => {
                              button.action();
                              return closePopUp();
                          }}> {dictionary[language][button.text]}</button>
                      })}
                    <button onClick={closePopUp} key='default'> {dictionary[language].closePopUp}</button>
                </div>
            </div>}
        </>
    )
})

export default PopUp