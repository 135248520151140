import React, {useState, useEffect, useMemo} from 'react';
import CancelBillPopUp from './CancelBillPopUp';
import appGlobalStore from '../store/appGlobalStore';
import {observer} from 'mobx-react';
import actions from '../actions'
import appStateStore from '../store/appStateStore';
import DateFilter from './DateFilter';
import billStore from '../store/billStore';

const BillList = observer((props) => {
    const {billList, setBillList, totalBillListLength} = billStore;
    const [sort, setSort] = useState({
        field: 'CreationTime',
        direction: 'backward'
    });
    const {dictionary, billListTimer,} = appGlobalStore;
    const {language, showCancelBillPopUp, lastUpdate, refreshSort, billListLength, setShowPreloader, billFilter, billListStartLength, setBillListLength} = appStateStore;

    useEffect(() => {
        let timer = setInterval(() => {
            setBillListLength(billListStartLength);
            actions.getBillList(false);
        }, billListTimer);
        return (() => clearInterval(timer))
    });

    useMemo(() => {
        setShowPreloader(true);
        return actions.getBillList(false);
    }, [billFilter]);

    useEffect(() => {
        sortBills();
    }, [refreshSort,sort]);

    function onClickHandler() {
        setShowPreloader(true);
        return actions.getBillList(true);
    }

    function handleSortClick(event) {
        if (!Array.isArray(billList)) return;
        let typeDataSort = event.target.getAttribute('data-sort')|| event.currentTarget.getAttribute('data-sort');
        let currentDirectionSort = typeDataSort === sort.field ? sort.direction === 'forward' ? 'backward' : 'forward' : 'forward';
        setSort({
            field: typeDataSort,
            direction: currentDirectionSort
        });
    }

    function sortBills() {
        if (!Array.isArray(billList)) return;
        let sorter = actions.billsSorter(sort.field, sort.direction);
        let newBillList = [];
        for (let i = 0; i < billList.length; i++) {
            newBillList.push(Object.assign({}, billList[i]));
        }
        let sortedBillList = newBillList.sort(sorter);
        setBillList(sortedBillList);
    }

    return (<div className='bill-list'>
        <h2>{dictionary[language].invoicesBy}</h2>
        {lastUpdate &&
        <p className='invalid-feedback'>{dictionary[language].lastUpdate + ' ' + lastUpdate.toTimeString().substr(0, 8)}</p>}
        <div className='billListWrapper'>
            <DateFilter/>
            <div className='select-wrapper select-wrapper-billist '>
                <input id='allTab' type='radio' name='billStatus' value=''
                       onClick={(event) => actions.selectInvoiceStatus.bind(actions)(event)}/> <label
                htmlFor='allTab'>{dictionary[language].allTab}</label>
                <input id='activeTab' type='radio' name='billStatus' value='Active'
                       onClick={(event) => actions.selectInvoiceStatus.bind(actions)(event)}
                       defaultChecked/> <label
                htmlFor='activeTab'>{dictionary[language].active}</label>
                <input id='paidTab' type='radio' name='billStatus' value='Payed'
                       onClick={(event) => actions.selectInvoiceStatus.bind(actions)(event)}/> <label
                htmlFor='paidTab'>{dictionary[language].paid}</label>
                <input id='canceledTab' type='radio' name='billStatus' value='Cancelled'
                       onClick={(event) => actions.selectInvoiceStatus.bind(actions)(event)}/>
                <label htmlFor='canceledTab'>{dictionary[language].canceled}</label>
                <input id='expiredTab' type='radio' name='billStatus' value='Expired'
                       onClick={(event) => actions.selectInvoiceStatus.bind(actions)(event)}/> <label
                htmlFor='expiredTab'>{dictionary[language].expired}</label>
            </div>
            <table className={(billList ? '' : 'emptyBillList') + (billFilter === '' ? "" : "noStatusField")}>
                {billList ? <thead>
                <tr>
                    <th className={'time sortedField' + (sort.field === 'CreationTime' ? sort.direction === 'forward' ? ' activeSort forward' : ' activeSort backward' : '')}
                        data-sort='CreationTime'
                        onClick={handleSortClick}><span>{dictionary[language].time}</span></th>
                    <th className='billID'>{dictionary[language].billID}</th>
                    <th className='description'>{dictionary[language].description}</th>
                    <th className={'thAmount amount sortedField'+ (sort.field === 'Amount' ? sort.direction === 'forward' ? ' activeSort forward' : ' activeSort backward' : '')} data-sort='Amount'
                        onClick={handleSortClick}><span>{dictionary[language].amount}</span></th>
                    {billFilter === '' && <th className={'Status sortedField'+ (sort.field === 'Status' ? sort.direction === 'forward' ? ' activeSort forward' : ' activeSort backward' : '')} data-sort='Status'
                                              onClick={handleSortClick}><span>{dictionary[language].status}</span></th>}
                    <th className='deleteBillBtn'></th>
                </tr>
                </thead> : <thead></thead>}
                <tbody>
                {billList ?
                    Array.isArray(billList) ? billList.map((bill, index) => <tr key={index}
                                                                                data-prid={bill._attributes.PaymentRid}
                                                                                data-id={bill._attributes.Id}
                                                                                data-status={bill._attributes.Status}
                                                                                data-sum={bill._attributes.Amount === '0' && bill._attributes.Status === 'Payed' ? bill._attributes.PaidAmount : bill._attributes.Amount}
                                                                                data-qrcode={bill.QrCodeData && bill.QrCodeData._text.indexOf('http') !== -1 ? bill.QrCodeData._text : ''}
                                                                                onClick={actions.showBillDetails.bind(actions)}>
                        <td className='time'>{bill._attributes.CreationTime.substr(11, 5)}</td>
                        <td className='billID'>{bill._attributes.Id} ({bill._attributes.PaymentRid})</td>
                        <td className='desc'>{bill.Description ? bill.Description._text : ''}</td>
                        <td className='amount'>{
                            Number((bill._attributes.Amount === '0' && bill._attributes.Status === 'Payed')
                                ||
                            (bill._attributes.Status === 'Payed' && bill._attributes.PaidAmount !== '0' && bill._attributes.Amount !== '0')
                                ? bill._attributes.PaidAmount
                                : bill._attributes.Amount
                            ).toFixed(2)}</td>
                        {billFilter === '' && <td className='status'><span>{(dictionary[language].statuses[bill._attributes.Status] && dictionary[language].statuses[bill._attributes.Status].text) || dictionary[language].statuses.defaultStatus.text}</span></td>}
                        {bill._attributes.Status === 'Active' || bill._attributes.Status === 'Payed' ?
                            <td data-prid={bill._attributes.PaymentRid} data-id={bill._attributes.Id}
                                data-status={bill._attributes.Status}
                                onClick={actions.cancelBill.bind(actions)}
                                className='delete-bill'></td> :
                            <td></td>}
                    </tr>) : <tr data-prid={billList._attributes.PaymentRid} data-id={billList._attributes.Id}
                                 data-status={billList._attributes.Status}
                                 onClick={actions.showBillDetails.bind(actions)}>

                        <td className='time'>{billList._attributes.CreationTime.substr(11, 5)}</td>
                        <td className='billID'>{billList._attributes.Id} ({billList._attributes.PaymentRid})</td>
                        <td className='desc'>{billList.Description ? billList.Description._text : ''}</td>
                        <td className='amount'>
                            {Number((billList._attributes.Amount === '0' && billList._attributes.Status === 'Payed')
                                ||
                                (billList._attributes.Status === 'Payed' && billList._attributes.PaidAmount !== '0' && billList._attributes.Amount !== '0')
                                    ? billList._attributes.PaidAmount
                                    : billList._attributes.Amount
                            ).toFixed(2)}
                        </td>
                        {billFilter === '' && <td className='status'><span>{(dictionary[language].statuses[billList._attributes.Status] && dictionary[language].statuses[billList._attributes.Status].text) || dictionary[language].statuses.defaultStatus.text}</span></td>}
                        {(billList._attributes.Status === 'Active' || billList._attributes.Status === 'Payed') &&
                        <td data-prid={billList._attributes.PaymentRid} data-id={billList._attributes.Id}
                            data-status={billList._attributes.Status}
                            onClick={actions.cancelBill.bind(actions)}
                            className='delete-bill'>
                        </td>}
                    </tr> : <tr className='noInvoices'>
                        <td colSpan='5'
                            className='noInvoices'>{dictionary[language].noInvoices}</td>
                    </tr>}
                </tbody>
            </table>
            {(totalBillListLength > billListLength) && <button className='addBills' type='Button' onClick={onClickHandler}>{dictionary[language].addBills}</button>}
        </div>
        {showCancelBillPopUp &&
        <CancelBillPopUp/>}
    </div>)
})

export default BillList;


