import React from 'react';
import appStateStore from '../store/appStateStore';
import appGlobalStore from '../store/appGlobalStore';
import reloadIcon from '../images/reload.svg'
import actions from '../actions';


function RefreshBillListButton() {
    const {setBillListLength} = appStateStore;
    const {billListStartLength} = appGlobalStore;

    async function onClickHandler() {
        setBillListLength(billListStartLength);
        await actions.getBillList(false);
    }

    return <>
        <button className='reload-button' onClick={onClickHandler}>
            <img alt='reload' src={reloadIcon}/>
        </button>
    </>
}

export default RefreshBillListButton