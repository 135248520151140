import React , { useState , useEffect , useRef , useMemo } from 'react';
import QRCode from 'qrcode';
import dublicate from '../images/copy.svg';
import appGlobalStore from '../store/appGlobalStore';
import billStore from '../store/billStore';
import {observer} from 'mobx-react'
import actions from '../actions';
import appStateStore from '../store/appStateStore';


const CurrentBill = observer(() => {
    const {eInvoicing, newBillTimer, eInvoicingLink, mobiCashLink, dictionary} = appGlobalStore;
    const {language, setTosterText} = appStateStore;
    const { processBillData = {} } = billStore;
    const { qrCodeData, billStatus, id, paymentRid, creationTime, expTime, description, amount } = processBillData;
    const copyLinkInput = useRef(null);
    const qrCodeWrapper = useRef(null);
    const qrCode = useRef(null);
    const [typeLink, setTypeLink] = useState('mobicash');
    const abortController = new AbortController();
    const signal = abortController.signal;
    const isModeNeeded = qrCodeData && qrCodeData.indexOf('qr.nspk') !== -1;
    const statusLexems = dictionary[language].statuses && dictionary[language].statuses[billStatus]
      ? dictionary[language].statuses[billStatus] : dictionary[language].statuses.defaultStatus;
    const link = useMemo(() => {
        if (typeLink === 'mobicash') {
            if (isModeNeeded) {
                return qrCodeData
            }

            return mobiCashLink + id;
        }

        return eInvoicingLink + id + '&PaymentRid=' + paymentRid
    });
    let timer;

    useEffect(() => {
        if (typeLink === 'billDetails') return;

        if (qrCodeWrapper.current.querySelector('canvas')) {
            qrCodeWrapper.current.querySelector('canvas').parentNode.removeChild(qrCodeWrapper.current.querySelector('canvas'))
        }

        QRCode.toCanvas(
          link,
          {scale: 1, errorCorrectionLevel: 'H', width: typeLink === 'mobicash'|| billStatus !== 'Active'?'244':'164', margin: 0},
          function (err, canvas) {
            if (err) throw err;

            const container = qrCodeWrapper.current;
            const qrCodePlace = qrCode.current;
            qrCodePlace.appendChild(canvas);
            container.classList.add('shownQr');
        })
    }, [link]);

    useEffect(() => {
        actions.readBillDetails.bind(actions)(abortController, signal);
        if (!copyLinkInput.current) return;
        const input = copyLinkInput.current;
        const value = input.value;
        input.focus();
        input.setSelectionRange(value.length, value.length);
    }, []);

    useEffect(() => {
            if (billStatus !== 'Active' && billStatus !== 'Paid') return;
            timer = setTimeout(actions.readBillDetails.bind(actions), newBillTimer, abortController, signal);
            return (() => {
                clearTimeout(timer);
                abortController.abort();
            })
        }, [processBillData]
    );

    function copyToClipboard() {
        let copyText = copyLinkInput.current;
        copyText.select();
        document.execCommand('copy');
        setTosterText(dictionary[language].copyLinkSuccess);
    }

    return (
        <div id="qr-code-wrapper" ref={qrCodeWrapper}>
            <div id="container-qr-code">
                <div className="paymentRid">{statusLexems.headerText}{paymentRid})</div>
                <div className="select-wrapper">
                    <input id="billDetails" type="radio" name="typeLink" value="mobicash"
                           onClick={() => {
                               if (typeLink !== "billDetails") {
                                   setTypeLink("billDetails");
                               }
                           }}/><label htmlFor="billDetails">{dictionary[language].billDetails}</label>
                    <input id="mobicashTab" type="radio" name="typeLink" value="mobicash"
                           onClick={() => {
                               if (typeLink !== "mobicash") {
                                   setTypeLink("mobicash");
                               }
                           }} defaultChecked/> <label htmlFor="mobicashTab">{ isModeNeeded ? 'SBP' : 'MobiCash' }</label>
                    {eInvoicing && <> <input id="eInvoicingTab" type="radio" name="typeLink"
                                             value="eInvoicing"
                                             onClick={() => {
                                                 if (typeLink !== "eInvoicing") {
                                                     setTypeLink("eInvoicing");
                                                 }
                                             }}/> <label htmlFor="eInvoicingTab">eInvoicing</label></>
                    }
                </div>
                {typeLink !== "billDetails" && <div id="qr-code" ref={qrCode}
                                                    className={billStatus !== "Active" ? "noActiveBill" : ""}>
                                                    {typeLink === "mobicash" && <div className={("qr-code-img" + (qrCodeData ? " sbp-logo" : " mobicash-logo"))}/>}
                                                </div>}
                {typeLink === "billDetails" && <div
                    className={billStatus !== "Active" ? "billDetailsWrapper noActiveBill" : "billDetailsWrapper"}>
                    <div className="billDetailsRow">
                        <div>{dictionary[language].number}</div>
                        <div>{id} ({paymentRid})</div>
                    </div>
                    <div className="billDetailsRow">
                        <div>{dictionary[language].creationTime}</div>
                        <div>{creationTime}</div>
                    </div>
                    <div className="billDetailsRow">
                        <div>{dictionary[language].expirationTime}</div>
                        <div>{expTime}</div>
                    </div>
                    <div className="billDetailsRow">
                        <div>{dictionary[language].description}</div>
                        <div>{description}</div>
                    </div>
                    <div className="billDetailsRow">
                        <div>{dictionary[language].amount}</div>
                        <div>{amount}</div>
                    </div>
                </div>}
                {statusLexems.bigText
                  ? <div className={"paidBillContainer " + statusLexems.class} >{statusLexems.bigText}! </div>
                    : ""}
                {(billStatus === "Active") && eInvoicing && typeLink === "eInvoicing" ? <>
                    <div className="eInvoicingCopyLinkWrapper">
                        <div className="eInvoicingCopyLinkTitle">{dictionary[language].eInvoicinglink}</div>
                        <div className="eInvoicingCopyLinkInnerWrapper">
                        <input ref={copyLinkInput} className="eInvoicingLink"
                               defaultValue={eInvoicingLink + id + "&PaymentRid=" + paymentRid + (isModeNeeded ? "&mode=checkout" : "")}
                               readOnly={true}/>
                            <span>...</span><img src={dublicate} onClick={copyToClipboard} alt={dictionary[language].copyLink}/></div>
                    </div>
                </> : ""}
                <button type="button" className="closeButton"
                        onClick={() => actions.closeQRCodeWindow.bind(actions)(qrCodeWrapper)}>{dictionary[language].close}</button>
            </div>
        </div>
    )
})

export default CurrentBill