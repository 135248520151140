import React from 'react';
import appStateStore from "../store/appStateStore";
import {observer} from "mobx-react"
import payeeStore from "../store/payeeStore";


const PayeeName = observer(()=> {
    let {language} = appStateStore;
    let {merchantName, payeeLogo} = payeeStore;

    return (
        <React.Fragment>
            <div className="container-payee-name">
                <div className="payeeData">
                    {payeeLogo ? <img alt="logo" src={`data:image/png;base64,${payeeLogo}`}/> : ''}
                    <h2>{merchantName[language] ? merchantName[language] : merchantName.en}</h2>
                </div>
            </div>
        </React.Fragment>
    )
});

export default PayeeName;