import {observable, action} from "mobx";

class BillStore {
    @observable processBillData = null;
    @observable billList = null;
    @observable totalBillListLength = null;

    @action setProcessBill = data => this.processBillData = data
    @action setBillList = date => this.billList = date
    @action addBillList = date => this.billList = this.billList.concat(date);
    @action setTotalBillListLength = data => this.totalBillListLength = data
}


const billStore = new BillStore();
export default billStore;