import React, {useState} from 'react'
import appGlobalStore from "../store/appGlobalStore";
import {observer} from "mobx-react"
import actions from "../actions";
import appStateStore from "../store/appStateStore";

const CancelBillPopUp = observer((props) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const {dictionary} = appGlobalStore;
    const {language} = appStateStore;
    return (
        <div className="popUpBackShadow">
            <div className="popUpWrapper">
                <div
                    className="popupText">{dictionary[language].confirmDeleteBill}</div>
                <div className="popUpButtonWrapper">
                    <button type="button" className="cancelBillButton" disabled={isButtonDisabled}
                            onClick={(event) => actions.deleteBill.bind(actions)(event, setIsButtonDisabled)}>{dictionary[language].acceptDeleteBill}</button>
                    <button type="button" onClick={actions.cancel.bind(actions)}
                            disabled={isButtonDisabled}>{dictionary[language].closePopUp}</button>
                </div>
            </div>

        </div>
    )
})

export default CancelBillPopUp