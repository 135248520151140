import React, {useEffect} from 'react';
import appGlobalStore from "../store/appGlobalStore";
import {observer} from "mobx-react"
import appStateStore from "../store/appStateStore";


const Toster = observer((props) => {
    const {dictionary} = appGlobalStore;
    const {tosterText, setTosterText, language} = appStateStore;
    let timer;

    useEffect(() => {
            if (tosterText === dictionary[language].sessionWarning) {
                return
            }
            timer = setTimeout(() => setTosterText(null), 5000);
            return (() => {
                clearTimeout(timer)
            })
        }
    )

    return (<>
            {tosterText && <div className="toster">{tosterText}</div>}
        </>
    )
})

export default Toster