import React from 'react';
import appGlobalStore from "../store/appGlobalStore";
import appStateStore from "../store/appStateStore";
import {observer} from "mobx-react";


const PopUpNew = observer(() => {
    const { dictionary} = appGlobalStore;
    const {popUpNewStatus, setPopUpNewStatus, language} = appStateStore;
    const closePopUp = () => {
        setPopUpNewStatus(null);
    };

    return (<>
            {popUpNewStatus && <div className="popUpBackShadow">
                <div className="popUpNewInner">
                    <div className="merchantServiceImg"></div>
                    <div className="popUpNewMessage">
                        <div className="merchantService">{dictionary[language].merchantService}</div>
                        <div>{dictionary[language].persAccError.replace("[persAcc]", popUpNewStatus)}</div>
                    </div>
                    <button onClick={closePopUp}> {dictionary[language].closePopUpNew}</button>
                </div>
            </div>}
        </>
    )
});

export default PopUpNew;