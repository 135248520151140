import React from 'react';
import ru from '../images/langRu.svg';
import en from '../images/langEn.svg';
import br from '../images/langBr.svg';
import appGlobalStore from "../store/appGlobalStore";
import appStateStore from "../store/appStateStore";
import {observer} from "mobx-react"
import actions from "../actions";


const LanguageChange = observer((props) => {
    const {dictionary} = appGlobalStore;
    const {language, isUserAuthorized} = appStateStore;

    const languageImage = {'ru': ru, 'en': en, 'pt': br};


    return (
        <div className="langFieldWrapper">
            <div className="currentLangField"><img src={languageImage[language]}
                                                   alt={dictionary[language].selectLang}/>
                <div className="tick"></div>
            </div>
            <div className={isUserAuthorized ? "langFieldInnerWrapper" : "langFieldInnerWrapper notAuth"}>
                <div className={isUserAuthorized ? "langFieldInner" : "langFieldInner notAuth"}>
                    <div data-lang="ru" onClick={actions.changeLang.bind(actions)}><img src={ru}
                                                                                        alt=""/> {dictionary[language].RU}
                    </div>
                    <div data-lang="en" onClick={actions.changeLang.bind(actions)}><img src={en}
                                                                                        alt=""/>{dictionary[language].EN}
                    </div>
                    {/*<div data-lang="pt" onClick={actions.changeLang.bind(actions)}><img src={br}*/}
                                                                                        {/*alt=""/>{dictionary[language].BR}*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )

})

export default LanguageChange