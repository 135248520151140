import React from 'react';
import ExitButton from './ExitButton';
import logo from '../images/icon.png'
import LanguageChange from "./LanguageChange";
import appStateStore from "../store/appStateStore";
import {observer} from "mobx-react"


const Header = observer((props)=> {
    const {isUserAuthorized} = appStateStore;
    return (<React.Fragment>
        <div className='header'>
            <div className='logo-wrapper'><img src={logo} alt='logo'/><span
                className='mobi-cash-title'> MobiCash</span><span className='json-editor-title'> WEB POS</span>
            </div>
            {<LanguageChange/>}
            {isUserAuthorized && <ExitButton/>}

        </div>
    </React.Fragment>)
})

export default Header;