import React from 'react';
import banner from '../images/banner.png';

const Banner = (props) => {

    return (
        <div className="banner">
            <a href="https://adportal.mobicash.ru/referalnayaprogramma" target="_blank" name="bannerToReferalnayaprogramma">
                <img src={banner} alt='banner'/>
            </a>
        </div>
    )
};

export default Banner;