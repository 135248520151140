import React, {useState} from 'react';
import eyeOpen from '../images/eye-open.svg';
import eyeClose from '../images/eye-close.svg';
import icon from '../images/icon.png';
import LanguageChange from './LanguageChange';
import appGlobalStore from '../store/appGlobalStore';
import appStateStore from '../store/appStateStore';
import {observer} from 'mobx-react';
import actions from '../actions';
import Cookies from 'universal-cookie';


const SingIn = observer(() => {
    const cookies = new Cookies();
    const savedLogin = cookies.get('savedLogin');
    const {dictionary} = appGlobalStore;
    const {language} = appStateStore;
    const [showPassword, setShowPassword] = useState(false);
    const [loginValue, setLoginValue] = useState(savedLogin ? savedLogin : '');
    const [rememberMe, setRememberMe] = useState(false);
    const [passwordValue, setPasswordValue] = useState('');
    const [errorFeedbackClass, setErrorFeedbackClass] = useState('invalid-feedback invisible');
    const handleKeyPressSingInForm = function (event) {
        if (event.code === 'Enter' || event.key === 'Enter' || event.which === '13') {
            event.preventDefault();
            actions.singIn.bind(actions)(loginValue, passwordValue, setErrorFeedbackClass)
        }
    };

    return (
        <div className='container-sign-in'>
            <div className='app-info'>
                <div className='log-in-title'><img src={icon} alt=''/><span
                    className='mobi-cash-title'>MobiCash</span> <span>WEB POS</span></div>
                <LanguageChange/>
            </div>
            <div className='login-form'>
                <h1>{dictionary[language].logIn}</h1>
                <form id='singInForm'                    onSubmit={(event) => {
                    event.preventDefault();
                    actions.singIn.bind(actions)(loginValue, passwordValue, setErrorFeedbackClass, rememberMe)
                }}
                    onKeyPress={handleKeyPressSingInForm}>
                    <div className='form-group'>
                        <label htmlFor='inputLogin'>{dictionary[language].login}</label>
                        <input
                            id='inputLogin'
                            name='login'
                            required={true}
                            onChange={(event) => setLoginValue(event.target.value)}
                            autoComplete='on'
                            defaultValue={savedLogin ? savedLogin : ''}
                        />
                        <label
                            htmlFor='inputPassword'>{dictionary[language].password}</label>
                        <div className='password-field-wrapper'>
                            <input
                                id='inputPassword'
                                name='password'
                                type={showPassword ? 'text' : 'password'}
                                required={true}
                                value={actions.unmaskingPassword(passwordValue)}
                                onChange={(event) => setPasswordValue(actions.maskingPassword(event.target.value))}
                                autoComplete='on'
                            />
                            <img src={showPassword ? eyeOpen : eyeClose}
                                 alt=''
                                 className='show-password'
                                 onClick={() => {
                                     showPassword ? setShowPassword(false) : setShowPassword(true)
                                 }}/></div>
                        <input id='rememberMe' type='checkbox' onChange={(event) => {
                            setRememberMe(event.target.checked)
                        }}/> <label className='labelForCheckbox'
                                                                        htmlFor='rememberMe'>{dictionary[language].rememberMe}</label>
                    </div>
                </form>
                <div
                    className={errorFeedbackClass}>{dictionary[language].errorFeedback}</div>
                <button form='singInForm'
                    className='btn'>{dictionary[language].logIn}</button>
            </div>
        </div>
    )
})

export default SingIn;