import React, {useState} from 'react';
import appGlobalStore from '../store/appGlobalStore';
import payeeStore from '../store/payeeStore';
import {observer} from 'mobx-react'
import actions from '../actions';
import appStateStore from '../store/appStateStore';
import InputMask from 'react-input-mask';

const CheckPersAcc = observer((props) => {

    const [personalAcc, setPersonalAcc] = useState('');
    let {dictionary} = appGlobalStore;
    let {language, setIsPersAccExist} = appStateStore;
    let {personalAccount, setPersonalAccount,  personalAccountMask, setCustomerData} = payeeStore;

    if (personalAccountMask !== null && personalAccountMask !== undefined && personalAccountMask.indexOf('0') !== -1) {
        personalAccountMask = personalAccountMask.replaceAll('0', '9');
    }

    return (
        <React.Fragment>
            <div className="container-check-acc">
                <form className="container-check-acc-form" onSubmit={actions.checkPersAcc.bind(actions)}>
                    <fieldset>
                        <label htmlFor="persAcc" className="is-required">{dictionary[language].persAcc}</label>
                        <InputMask name="persAcc" id="persAcc" mask={personalAccountMask}
                                   data-field="persAcc"
                                   value={personalAcc}
                                   placeholder={dictionary[language].clientPersonalAccPlaceholder}
                                   required={true}
                                   maskChar={null} inputMode="decimal"
                                   readOnly={personalAccount}
                                   onChange={(event) => setPersonalAcc(event.target.value)}/>
                    </fieldset>
                    <div className="button-group">
                        <button type="button"
                            className={"changeBTN " + (personalAccount ? "" : "deleteButton")}
                                onClick={()=> {
                                    setPersonalAccount(null);
                                    setPersonalAcc("");
                                    setIsPersAccExist(false);
                                    setCustomerData({})
                                }}>{dictionary[language].changeBTN}</button>
                        <button type="Submit" className={"continueBTN " + (!personalAcc || personalAccount ? "disableSubmitData" : "")}
                                disabled={!personalAcc || personalAccount}>{dictionary[language].continueBTN}</button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
});

export default CheckPersAcc