import appGlobalStore from './store/appGlobalStore';
import appStateStore from './store/appStateStore';
import billStore from './store/billStore';
import payeeStore from './store/payeeStore';
import Cookies from 'universal-cookie';

class Smart {
    constructor({cookies, billStore, payeeStore, appStateStore, appGlobalStore}) {
        this.appGlobalStore = appGlobalStore;
        this.billStore = billStore;
        this.payeeStore = payeeStore;
        this.appStateStore = appStateStore;
        this.cookies = cookies;
    }

    cancel() {
        this.appStateStore.setShowCancelBillPopUp(false);
        this.billStore.setProcessBill(null);
    }

    cancelBill(event) {
        this.billStore.setProcessBill({
            id: event.target.getAttribute('data-id'),
            paymentRid: event.target.getAttribute('data-prid'),
            billStatus: event.target.getAttribute('data-status')
        });
        this.appStateStore.setShowCancelBillPopUp(true);
    }

    async changeLang({target}) {
        const currentLang = target.dataset.lang || target.parentElement.dataset.lang;
        const isPayeeSet = this.appStateStore.isUserAuthorized
          || (this.payeeStore.merchantName && !this.payeeStore.merchantName[currentLang])
          || (this.payeeStore.billTypes && !this.payeeStore.billTypes[currentLang])
          || (this.payeeStore.purposeList && !this.payeeStore.purposeList[currentLang]);
        this.cookies.set('perfectLang', currentLang);
        
        if (isPayeeSet) {
            this.appStateStore.setShowPreloader(true);
            
            await this.readPayeeByLang({
                rid: this.payeeStore.rid,
                lang: currentLang.toUpperCase()
            }, true)
        }
        this.appStateStore.setLanguage(currentLang);
    }

    closeQRCodeWindow(qrCodeWrapper) {
        this.billStore.setProcessBill(null);

        let container = qrCodeWrapper.current;
        container.classList.remove('shownQr');
        
        if (container.querySelector('canvas')) {
            container.querySelector('canvas').parentNode.removeChild(container.querySelector('canvas'));
        }
        
        this.appStateStore.setTosterText(null);
    }

    getSubscription() {
        if ('serviceWorker' in navigator) {
            return navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
                return serviceWorkerRegistration.pushManager.getSubscription();
            })
        } else return Promise.resolve();
    }

    errorHandler(errMessage) {
        const errorsList = ['AmountIsTooSmall', 'AmountIsTooBig', 'LoginTryExceeded', 'SystemError'];
        const {connectionError} = this.appGlobalStore.dictionary[this.appStateStore.language];

        if (this.appStateStore.showPreloader) {
            this.appStateStore.setShowPreloader(false);
        }
        
        if (errMessage === 'AuthenticationLost') {
            this.singOut();
        } else if (errMessage === 'TosterConnectionError') {
            this.appStateStore.setTosterText(connectionError);
        } else if (errMessage === 'InvalidLogin') {
            localStorage.clear();
        } else if (errorsList.includes(errMessage)) {
            const errMessageDictionary = errMessage.charAt(0).toLowerCase() + errMessage.slice(1)
              + (errMessage.endsWith('Error') ? '' : 'Error');
            this.appStateStore.setPopUpStatus({
                status: errMessageDictionary
            });
        } else {
            this.appStateStore.setPopUpStatus({
                status: 'connectionError'
            });
        }
    }

    async createBill(event) {
        let permanentData = new FormData(event.target);
        let data = {};
        
        this.appStateStore.setShowPreloader(true);
        event.preventDefault();
        
        for (let [key, value] of permanentData.entries()) {
            if (value) {
                data[key] = value;
            }
        }
        
        data.lang = this.appStateStore.language.toUpperCase();

        try {
            const rid = this.payeeStore.riid ? this.payeeStore.riid : '';

            if (rid === 'RU-18-74565') {
                const params = Object.keys(data).filter(item => {
                    return (/^param_name_(\w)$/).test(item);
                });
                const numberParam = params.length + 1;
                const paramSendMeters = {
                    [`param_name_${numberParam}`]: 'SendMeters',
                    [`param_val_${numberParam}`]: '0'
                };

                Object.assign(data, paramSendMeters);
            }

            await this.clientLog('CreateBill request: body = ', data);

            const subscription = await this.getSubscription();

            if (subscription) {
                data.subscription = btoa(JSON.stringify(subscription));
            }
            
            const createBill = await this.sendRequest('./api/txm/CreateBill', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('jwt')
                }
            });

            this.appStateStore.setBillListLength(this.appGlobalStore.billListStartLength);
            this.getBillList(false);

            await this.clientLog('CreateBill response: bill id = ', createBill.CreateBill._attributes.Id);

            this.billStore.setProcessBill({
                paymentRid: createBill.CreateBill._attributes.PaymentRid,
                id: createBill.CreateBill._attributes.Id,
                billStatus: 'Active',
                qrCodeData: (createBill.CreateBill.QrCodeData && createBill.CreateBill.QrCodeData._text.indexOf('http') !== -1) ? createBill.CreateBill.QrCodeData._text : ''
            });

            this.appStateStore.setShowPreloader(false);
        } catch (err) {
            console.log(err);
            this.errorHandler(err.message);
        }
    }

    billsSorter(sortByData, direction) {
        return function (a, b) {
            if (!isNaN(+a._attributes[sortByData])) {
                a = +a._attributes[sortByData];
                b = +b._attributes[sortByData];

            } else {
                a = a._attributes[sortByData];
                b = b._attributes[sortByData];
            }
            return a > b ? direction === 'forward' ? 1 : -1 : direction === 'forward' ? -1 : 1;
        }
    }

    async deleteBill(event, setIsButtonDisabled) {
        let cancelBill;

        this.appStateStore.setShowPreloader(true);
        setIsButtonDisabled(true);

        if (this.cookies.get('role') && this.cookies.get('role') !== 'Cashier') {
            try {
                if (this.billStore.processBillData.billStatus === 'Active') {
                    cancelBill = await this.sendRequest('./api/txm/CancelBill', {
                        method: 'POST',
                        body: JSON.stringify({
                            Id: this.billStore.processBillData.id,
                            PaymentRid: this.billStore.processBillData.paymentRid
                        }),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': localStorage.getItem('jwt')
                        },
                    })
                } else {
                    cancelBill = await this.sendRequest('./api/txm/ReturnPayment', {
                        method: 'POST',
                        body: JSON.stringify({
                            Id: this.billStore.processBillData.id,
                            PaymentRid: this.billStore.processBillData.paymentRid
                        }),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': localStorage.getItem('jwt')
                        },
                    });
                }

                if ((cancelBill.CancelBill && cancelBill.CancelBill._attributes.Status === 'Cancelled')
                  || (cancelBill.ReturnPayment && cancelBill.ReturnPayment._attributes.Status === 'Refunded')) {

                    this.appStateStore.setBillListLength(this.appGlobalStore.billListStartLength);
                    this.getBillList(false);
                } else {
                    throw new Error(cancelBill._attributes.Result + '');
                }
            } catch (err) {
                console.log(err);
                this.errorHandler(err.message);
            }
        } else {
            this.appStateStore.setPopUpStatus({
                status: 'insufficientRights'
            });
        }

        this.billStore.setProcessBill(null);
        this.appStateStore.setShowCancelBillPopUp(false);
        
        if (this.appStateStore.showPreloader) {
            this.appStateStore.setShowPreloader(false)
        }
    }

    async getBillList(isPartAdding) {
        const billFilter = this.appStateStore.billFilter;

        try {
            const readBillList = await this.sendRequest('./api/txm/ReadBillList', {
                method: 'POST',
                body: JSON.stringify({
                    FromCreateTime: this.transformDate(this.appStateStore.FromCreateTime, true),
                    ToCreateTime: this.transformDate(this.appStateStore.FromCreateTime, false),
                    Statuses: billFilter,
                    RowStart: (isPartAdding && this.appStateStore.billListLength) ? this.appStateStore.billListLength : 0,
                    BillListLength: isPartAdding ? this.appGlobalStore.billListAddLength :
                      ((this.appStateStore.billListLength && this.appStateStore.billListLength > this.appGlobalStore.billListStartLength)
                        ? this.appStateStore.billListLength : this.appGlobalStore.billListStartLength),
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('jwt')
                }
            });

            if (this.appStateStore.lastUpdate) this.appStateStore.setLastUpdate(null);

            if (isPartAdding) {
                this.billStore.addBillList(readBillList.ReadBillList.Bill);
            } else {
                this.billStore.setBillList(readBillList.ReadBillList.Bill);
            }

            this.billStore.setTotalBillListLength(readBillList.ReadBillList._attributes.TotalRowCount);
            this.appStateStore.setBillListLength(this.billStore.billList ? this.billStore.billList.length : 0);
            this.appStateStore.setRefreshSort(!this.appStateStore.refreshSort);

            if (this.appStateStore.showPreloader) {
                this.appStateStore.setShowPreloader(false);
            }
        } catch (err) {
            console.log(err);

            if (!this.appStateStore.lastUpdate) {
                this.appStateStore.setLastUpdate(new Date(new Date() - 5000));
            }

            this.errorHandler(err.message);
        }
    }

    async readBillDetails(abortController, signal) {
        try {
            await this.clientLog('ReadBill request: bill id (from store mobx) = ', this.billStore.processBillData.id);

            const readBill = await this.sendRequest('./api/txm/ReadBill', {
                method: 'POST',
                signal: signal,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('jwt')
                },
                body: JSON.stringify({
                    Id: this.billStore.processBillData.id
                })
            });

            this.billStore.setProcessBill(Object.assign({}, this.billStore.processBillData, {
                billStatus: readBill.ReadBill.Bill._attributes.Status,
                creationTime: (new Date(readBill.ReadBill.Bill._attributes.CreationTime)).toLocaleDateString(this.appStateStore.language) + ' ' + (new Date(readBill.ReadBill.Bill._attributes.CreationTime)).toLocaleTimeString(this.appStateStore.language),
                expTime: (new Date(readBill.ReadBill.Bill._attributes.ExpTime)).toLocaleDateString(this.appStateStore.language) + ' ' + (new Date(readBill.ReadBill.Bill._attributes.ExpTime)).toLocaleTimeString(this.appStateStore.language),
                amount: (readBill.ReadBill.Bill._attributes.Amount === '0' && readBill.ReadBill.Bill._attributes.Status === 'Payed')
                            || (readBill.ReadBill.Bill._attributes.Status === 'Payed' && readBill.ReadBill.Bill._attributes.PaidAmount !== '0' && readBill.ReadBill.Bill._attributes.Amount !== '0')
                            ? readBill.ReadBill.Bill._attributes.PaidAmount
                            : readBill.ReadBill.Bill._attributes.Amount,
                description: readBill.ReadBill.Bill.Description ? readBill.ReadBill.Bill.Description._text : '',
                qrCodeData: (readBill.ReadBill.Bill.QrCodeData && readBill.ReadBill.Bill.QrCodeData._text.indexOf('http') !== -1) ? readBill.ReadBill.Bill.QrCodeData._text : ''
            }));

            if (this.appStateStore.showPreloader) {
                this.appStateStore.setShowPreloader(false);
            }
        } catch (err) {
            console.log(err);
            this.appStateStore.setShowPreloader(false);

            if (err.name !== 'AbortError') {
                this.errorHandler(err.message === 'AuthenticationLost' ? err.message : 'TosterConnectionError');
            }
        }
    }

    selectInvoiceStatus(event) {
        this.appStateStore.setBillListLength(this.appGlobalStore.billListStartLength);
        this.appStateStore.setBillFilter(event.target.value);
    }

    showBillDetails(event) {
        if (event.target.className === 'delete-bill') return;

        this.appStateStore.setShowPreloader(true);
        this.billStore.setProcessBill({
            id: event.currentTarget.getAttribute('data-id'),
            paymentRid: event.currentTarget.getAttribute('data-prid'),
            billStatus: event.currentTarget.getAttribute('data-status'),
            qrCodeData: event.currentTarget.getAttribute('data-qrcode')
        });
    }

    singOut() {
        localStorage.clear();
        this.appStateStore.setIsUserAuthorized(false);
        this.appStateStore.setShowPreloader(false);
        this.appStateStore.setShowCancelBillPopUp(false);
        this.appStateStore.setTosterText(null);
        this.billStore.setProcessBill(null);
        this.billStore.setBillList(null);
        this.appStateStore.setLastUpdate(null);
        this.appStateStore.setBillListLength(null);
        this.appStateStore.setIsPersAccExist(false);
        this.appStateStore.setBillFilter('Active');
        this.payeeStore.setPayeeData({});
        this.payeeStore.setPersonalAccount(null);
        this.payeeStore.setCustomerData({});
        this.appStateStore.setIsAccCheckRequired(false);
        this.appStateStore.setIsRenderDescriptionNotNeeded(false);
        this.appStateStore.setIdsRequiredInputsOnRiid([]);
        this.appStateStore.setFixPersAccInfo(null);
        this.appStateStore.setPopUpStatus(null);
        this.appStateStore.setEmptyPurposeReplacement(null);
        this.appStateStore.clearPopUpSessionWarningTimer();
    }

    async readPayeeByLang(options, isLangAdd = false) {
        let lang = options.lang.toLowerCase();

        try {
            let payeeDataResult = await this.sendRequest('./api/txm/ReadPayee',
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('jwt'),
                }
            });

            let {ReadPayee} = payeeDataResult,
              payeeData = {
                merchantName: {},
                billTypes: {},
                purposeList: {}
            };

            if (!isLangAdd) {
                payeeData.rid = options.rid;
                payeeData.riid = ReadPayee.Rid._text;
                payeeData.currency = ReadPayee._attributes.Currency;
                payeeData.photo = ReadPayee.Photo ? payeeDataResult.ReadPayee.Photo._text : null;

                const payeeConfigResponse = await fetch('./api/clientConfigs/payeeConfigs?rid=' + payeeData.riid, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('jwt')
                    }
                });

                const payeeConfig = await payeeConfigResponse.json();

                this.appStateStore.setIsAccCheckRequired(payeeConfig.isAccCheckRequired);
                this.appStateStore.setIdsRequiredInputsOnRiid(payeeConfig.idsRequiredInputsOnRiid || []);
                this.appStateStore.setIsRenderDescriptionNotNeeded(payeeConfig.isRenderDescriptionNotNeeded);
                this.appStateStore.setFixPersAccInfo(payeeConfig.fixPersAccInfo);
                this.appStateStore.setEmptyPurposeReplacement(payeeConfig.emptyPurposeReplacement);

                try {
                    let service = ReadPayee.Services.Service;

                    if (Array.isArray(service)) {
                        payeeData.personalAccountMask = service.find(el => el._attributes.Rid === options.rid).Params.ParamValue.Val._text;
                    } else {
                        payeeData.personalAccountMask = ReadPayee.Services.Service.Params.ParamValue.Val._text;
                    }

                } catch { }

                if (!payeeData.personalAccountMask) {
                    try {
                        const maskRequest = await fetch('./api/mask', {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            }
                        });

                        if (maskRequest.ok) {
                            const masks = JSON.parse(await maskRequest.json()).mask;
                            const payeeMask = Object.keys(masks)
                              .find(maskRiid => maskRiid === payeeData.riid)

                            if (payeeMask) {
                                payeeData.personalAccountMask = masks[payeeMask];
                            } else {
                                throw new Error('No mask for this payee found')
                            }
                        } else {
                            throw new Error('Get payee mask error')
                        }

                    } catch (err) {
                        console.error(err.message)
                    }
                }
            }

            payeeData.merchantName[lang] = ReadPayee.Name._text;

            if (ReadPayee.BillTypes) {
                payeeData.billTypes[lang] = Array.isArray(ReadPayee.BillTypes.Type)
                  ? ReadPayee.BillTypes.Type : [ReadPayee.BillTypes.Type];
            }

            if (ReadPayee.Params !== undefined && ReadPayee.Params.ParamValue !== undefined) {
                let purposeList = Array.isArray(ReadPayee.Params.ParamValue) ? ReadPayee.Params.ParamValue : [ReadPayee.Params.ParamValue];

                if (purposeList.find(item => item._attributes.Rid === 'PurposeListWebPos')) {
                    payeeData.purposeList[lang] = JSON.parse((purposeList.find(item => item._attributes.Rid === 'PurposeListWebPos')).Val._text);
                }
            }

            if (!payeeData.purposeList[lang] && this.appStateStore.emptyPurposeReplacement && this.appStateStore.emptyPurposeReplacement[lang]) {
                payeeData.purposeList[lang] = [{Code: '000000001', Title: this.appStateStore.emptyPurposeReplacement[lang]}];
            }

            if (this.appStateStore.showPreloader) {
                this.appStateStore.setShowPreloader(false);
            }

            if (isLangAdd) {
                this.payeeStore.addPayeeData(payeeData);
            } else {
                this.payeeStore.setPayeeData(payeeData);
            }
        } catch (err) {
            console.log(err);
            this.errorHandler(err.message);
        }
    }

    maskingPassword(pass) {
        const _pass = '#as#' + pass + '#ik#';

        return btoa(encodeURIComponent(_pass));
    }

    unmaskingPassword(hash) {
        let pass = Buffer.from(hash, 'base64').toString('binary');

        return pass.substring(8, pass.length - 8);
    }

    async singIn(loginValue, passwordValue, setErrorFeedbackClass, rememberMe) {
        let language = this.appStateStore.language.toUpperCase(), rid;

        this.appStateStore.setShowPreloader(true);

        try{
            const employeeData = await this.sendRequest('./api/txm/ReadEmployee', {
                method: 'POST',
                body: JSON.stringify({
                    login: loginValue,
                    password: passwordValue,
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('jwt')
                }
            });

            if (employeeData.ReadEmployee && employeeData.ReadEmployee._attributes && employeeData.ReadEmployee._attributes.Role) {
                this.cookies.set('role', employeeData.ReadEmployee._attributes.Role);
            }

            rid = employeeData.ReadEmployee._attributes.PayeeId;

            await this.readPayeeByLang({
                rid,
                lang: language,
            });

            if (rememberMe) {
                this.cookies.set('savedLogin', loginValue);
            }

            if (this.appStateStore.showPreloader) {
                this.appStateStore.setShowPreloader(false);
            }

            return this.appStateStore.setIsUserAuthorized(true);
        } catch (err) {
            console.log(err);

            if (err.message === 'InvalidLogin') {
                if (setErrorFeedbackClass) {
                    setErrorFeedbackClass('invalid-feedback');
                }
            }

            this.errorHandler(['InvalidLogin', 'AuthenticationLost', 'LoginTryExceeded']
              .includes(err.message) ? err.message : 'SystemError');

            return this.appStateStore.setIsUserAuthorized(false);
        }
    }

    async checkPersAcc(event) {
        event.preventDefault();
        this.appStateStore.setShowPreloader(true);

        const persAcc = event.target.persAcc.value;

        await this.clientLog('CheckPersAcc request: PersAcc = ', persAcc);

        try {
            const persAccInfo = await this.sendRequest('./api/txm/GetPersAccInfo', {
                method: 'POST',
                body: JSON.stringify({
                    PersAcc: persAcc
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('jwt')
                }
            });

            await this.clientLog('CheckPersAcc response: Result = ', persAccInfo._attributes.Result);

            if (!persAccInfo._attributes || !persAccInfo._attributes.Result) {
                throw new Error(persAccInfo._attributes.Result);
            }

            if (this.appStateStore.fixPersAccInfo) {
                try {
                    const fixPersAccInfoFun = new Function('arguments', this.appStateStore.fixPersAccInfo);

                    this.payeeStore.setCustomerData(fixPersAccInfoFun(persAccInfo.GetPersAccInfo.PersAccInfo))
                } catch {}
            } else {
                this.payeeStore.setCustomerData(persAccInfo.GetPersAccInfo.PersAccInfo);
            }

            this.payeeStore.setPersonalAccount(persAcc);
            this.appStateStore.setIsPersAccExist(true);

            if (this.appStateStore.showPreloader) {
                this.appStateStore.setShowPreloader(false);
            }
        } catch (err) {
            console.log(err);

            if (err.message === 'InvalidCustomer' || err.message === 'InvalidPayee') {
                this.appStateStore.setShowPreloader(false);
                this.appStateStore.setPopUpNewStatus(persAcc);
            } else {
                this.errorHandler(err.message === 'AuthenticationLost'
                  ? err.message : 'TosterConnectionError');
            }
        }
    }

    clientLog(logMessage, data) {
        return this.sendRequest('./api/logClient', {
            method: 'POST',
            body: JSON.stringify({
                logMessage: encodeURI(logMessage),
                data: JSON.stringify(data)
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    async sendRequest(url, data) {
        const requestResponse = await fetch(url, data);

        if (requestResponse.ok) {
            const response = await requestResponse.json();

            if (response._attributes.Result !== 'Approved') {
                throw new Error(response._attributes.Result + '');
            }

            if (response.jwt && response.jwt !== localStorage.getItem('jwt')) {
                localStorage.setItem('jwt', response.jwt);

                this.appStateStore.runPopUpSessionWarningTimer(this.appGlobalStore.sessionMaxAge, {
                    warning: 'sessionWarning',
                    buttons: [{
                        text: 'sessionContinue',
                        action: () => {
                            return this.sendRequest('./api/jwt', {
                                headers: {
                                    'Accept': 'application/json',
                                    'Authorization': localStorage.getItem('jwt')
                                }
                            }).catch((err) => {
                                console.log(err);
                                this.errorHandler(err.message === 'AuthenticationLost' ? err.message : 'TosterConnectionError');
                            })
                        }
                    }]
                });
            }

            return response;
        } else {
            throw new Error(`HTTP error! status: ${requestResponse.status}`);
        }
    }

    transformDate(date, isStart) {
        const currentDate = isStart
          ? new Date(new Date(date).setHours(0, 0, 0, 0) - new Date(date).getTimezoneOffset() * 60 * 1000).toISOString().substr(0, 19)
          : new Date(new Date(date).setHours(23, 59, 59, 0) - new Date(date).getTimezoneOffset() * 60 * 1000).toISOString().substr(0, 19);
        const delta = new Date(date).getTimezoneOffset() / 60;
        const deltastr = '' + (delta > 0 ? '-' : '+') + (Math.trunc(delta) >= 10 ? Math.trunc(delta) : '0' + Math.abs(Math.trunc(delta))) + ':' + (Math.abs(delta - Math.trunc(delta)) * 60 > 10 ? Math.abs(delta - Math.trunc(delta)) * 60 : '0' + Math.abs(delta - Math.trunc(delta)) * 60);
        return currentDate + deltastr;
    }
}

const actions = new Smart({appGlobalStore, billStore, appStateStore, payeeStore, cookies: new Cookies()});
export default actions;
