import React, {useState, useMemo} from 'react';
import SingIn from './components/SingIn'
import Header from './components/Header'
import CheckPersAcc from './components/CheckPersAcc'
import PayeeName from './components/PayeeName'
import CreateBill from './components/CreateBill'
import BillList from './components/BillList'
import Toster from './components/Toster'
import Preloader from './components/Preloader';
import Cookies from 'universal-cookie';
import appGlobalStore from './store/appGlobalStore';
import appStateStore from './store/appStateStore';
import billStore from './store/billStore';
import {observer} from 'mobx-react';
import './styles/App.scss';
import actions from './actions';
import PopUp from './components/PopUp'
import PopUpNew from './components/PopUpNew'
import CurrentBill from './components/CurrentBill';
import Banner from './components/Banner';

const App = observer(() => {
    const {isUserAuthorized, language, setIsUserAuthorized, setLanguage, tosterText, showPreloader, popUpStatus, popUpNewStatus, showCancelBillPopUp, isPersAccExist, isAccCheckRequired} = appStateStore;
    const {dictionary, showBanner, setDictionary, setGlobal} = appGlobalStore;
    const {processBillData} = billStore;
    const [authorizedWasChecked, setAuthorizedWasChecked] = useState(false);
    const cookies = new Cookies();


    useMemo(async () => {
        let dictionaryRes = {};
        let currentLanguage = '';

        if (!dictionary) {
            const dictionaryResult = await fetch('./api/dictionary', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
            dictionaryRes = JSON.parse(await dictionaryResult.json());
            currentLanguage = cookies.get('perfectLang');

            setDictionary(dictionaryRes);

            currentLanguage = (currentLanguage ? currentLanguage : (window.navigator ? window.navigator.language : 'en'))
              .substring(0, 2).toLowerCase();

            if (!dictionaryRes[currentLanguage]) {
                currentLanguage = 'en';
            }

            setLanguage(currentLanguage);
        }

        const configsResult = await fetch('./api/clientConfigs/configs', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        const configs = await configsResult.json();
        setGlobal(configs);

        if (!authorizedWasChecked && dictionaryRes && Object.keys(dictionaryRes).length && currentLanguage && currentLanguage.length) {
            if (localStorage.getItem('jwt') && localStorage.getItem('jwt') !== 'undefined') {
                setIsUserAuthorized(await actions.singIn.bind(actions)());
            }
            setAuthorizedWasChecked(true);
        }

    }, [])

    return (authorizedWasChecked && dictionary && language) ? (
        <div className={isUserAuthorized ? 'App' : 'App logOnScreen'}>
            {isUserAuthorized && <Header/>}
            {!isUserAuthorized &&
            <SingIn/>}
            <div className='mainPageWrap'>
                {isUserAuthorized && <div className='container-create-bill-group'>
                    <PayeeName/>
                    {isAccCheckRequired &&
                      <CheckPersAcc/>}
                    {(!isAccCheckRequired || (isAccCheckRequired && isPersAccExist)) && <CreateBill/>}
                    {isAccCheckRequired && showBanner && <Banner/>}
                </div>}
                {isUserAuthorized && <BillList/>}
                {tosterText && <Toster/>}
            </div>
            {showPreloader && <Preloader/>}
            {popUpStatus && popUpStatus.status && <PopUp/>}
            {popUpNewStatus && <PopUpNew/>}
            {processBillData && !showCancelBillPopUp &&
            <CurrentBill/>}
        </div>
    ) : (<div>{popUpStatus && <PopUp/>}</div>);
});

export default App;
