import {observable, action} from "mobx";

class PayeeStore {
    @observable currency = null;
    @observable merchantName = null;
    @observable payeeLogo = null;
    @observable billTypes = null;
    @observable purposeList = null;
    @observable riid = null;
    @observable personalAccountMask = null;
    @observable personalAccount = null;
    @observable customerData = {};
    @observable rid = null;
    @observable payeeConfigs = {};

    @action setPayeeData = data => {
        this.merchantName = data.merchantName;
        this.currency = data.currency;
        this.payeeLogo = data.payeeLogo;
        this.billTypes = data.billTypes;
        this.riid = data.riid;
        this.purposeList = data.purposeList;
        this.personalAccountMask = data.personalAccountMask;
        this.rid = data.rid;
    };
    @action setPersonalAccount = data => this.personalAccount = data;
    @action setCustomerData = data => this.customerData = data;
    @action setPayeeConfigs = data => this.payeeConfigs = data;
    @action addPayeeData = data => {
        Object.assign(this.merchantName, data.merchantName);
        Object.assign(this.billTypes, data.billTypes);
        Object.assign(this.purposeList, data.purposeList);
    }
}

const payeeStore = new PayeeStore();
export default payeeStore;
