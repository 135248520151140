import React, {useState, useReducer, useEffect} from 'react';
import appGlobalStore from '../store/appGlobalStore';
import payeeStore from '../store/payeeStore';
import {observer} from 'mobx-react'
import actions from '../actions';
import appStateStore from '../store/appStateStore';
import InputMask from 'react-input-mask';

const CreateBill = observer(() => {

  const [phoneNumber, setPhoneNumber] = useState('');
  const [personalAcc, setPersonalAcc] = useState('');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [purpose, setPurpose] = useState('default');
  const [purposeValue, setPurposeValue] = useState();
  let {dictionary, currencyList} = appGlobalStore;
  let {language, idsRequiredInputsOnRiid, isAccCheckRequired, isRenderDescriptionNotNeeded} = appStateStore;
  let {currency, billTypes, purposeList, riid, customerData, personalAccount, personalAccountMask} = payeeStore;
  const [template, setTemplate] = useState(billTypes ? billTypes[language] ? billTypes[language][0] : '' : '');
  const [templateId, setTemplateId] = useState(billTypes
    && billTypes[language]
    && billTypes[language][0]
    && billTypes[language][0]._attributes
    && billTypes[language][0]._attributes.Id
      ? billTypes[language][0]._attributes.Id
      : 'default');

  useEffect(() => {
    if (templateId) {
      let currentTemplate = billTypes[language] ? billTypes[language].find(billType => billType._attributes.Id === templateId) : '';
      setPurpose('default');
      setPurposeValue('');
      setDescription('');
      setPersonalAcc('');
      setTemplate(currentTemplate);
      setIsCompletedForm({type: 'update_required_inputs'});
    }
    checkValidation('phoneNumber', phoneNumber);
  }, [language, templateId]);

  useEffect(() => {
    if (template && purposeList[language] && purposeList[language].length < 2) {
      setPurpose(purposeList[language][0].Code);
      setPurposeValue(JSON.stringify([{Code: purposeList[language][0].Code, Sum: amount * 100}]));
      setDescription(purposeList[language][0].Title);
      setIsCompletedForm({
        type: 'field_change',
        field: {'selectPurpose': true, 'description': true}
      });
    }
  }, [template]);

  useEffect(() => { },  [purpose, purposeValue, description])

  if (personalAccountMask && personalAccountMask.indexOf('0') !== -1) {
    personalAccountMask = personalAccountMask.replaceAll('0', '9');
  }

  const [isCompletedForm, setIsCompletedForm] = useReducer(reducerRequiredInputs, {template, riid, isAccCheckRequired}, initialCompleted);

  function reducerRequiredInputs(state, action) {
    let _fields = {};

    if (action.type === 'field_change') {
      Object.keys(state.fields).forEach(el => {
        if(el in action.field) {
          _fields[el] = action.field[el];
        } else {
          _fields[el] = state.fields[el];
        }
      })
    } else if (action.type === 'update_required_inputs') {
      _fields = getIdsRequiredInputs(template, riid, isAccCheckRequired);
      Object.keys(_fields).forEach(el => {
        return _fields[el] = state.fields[el] || false;
      })
    }

    const _isEnabledButton = Object.keys(_fields).reduce((result, field) => {
      return result && _fields[field];
    }, true);

    return {
      fields: _fields,
      isEnabledButton: _isEnabledButton
    };
  }

  function initialCompleted({template, riid, isAccCheckRequired}) {
    const idsRequiredInput = getIdsRequiredInputs(template, riid, isAccCheckRequired);

    return  {
      fields: {
        ...idsRequiredInput
      },
      isEnabledButton: false
    };
  }

  function getRequiredStatus(paramName) {
    return Object.keys(isCompletedForm.fields).includes(paramName);
  }

  function handleChangeForm(event) {
    const idField = event.target.id;
    const valueField = event.target.value;

    return checkValidation(idField, valueField);
  }

  function checkValidation(idField, valueField) {
    let isFieldValid = true;

    switch (idField) {
      case 'amount':
        if (/^[0-9]+[.,]?[0-9]{0,2}$|^\s*$/.test(valueField)) {
          const fixedAmount = valueField.replace(',', '.');
          setAmount(fixedAmount);

          if (purpose !== 'default') {
            setPurposeValue(JSON.stringify([{Code: purpose, Sum: (fixedAmount * 100)}]));
          }
        }

        if (!(/^[0-9]+[.,]?[0-9]{0,2}$/.test(valueField))) {
          isFieldValid = false;
        }

        break;
      case 'phoneNumber':
        let clearPhone = valueField.replaceAll(/[()_\s]/g, '');

        if (/^\+[0-9]{0,15}$|^\s*$/.test(clearPhone)) {
          setPhoneNumber(clearPhone);
        }

        if (!(/^\+[0-9]{0,15}$/.test(clearPhone))) {
          isFieldValid = false;
        }

        if(language === 'ru' && !(/^\+7+\d{10}$/.test(clearPhone))) {
          isFieldValid = false;
        }

        break;

      case 'description':
        setDescription(valueField);
        break;

      case 'PersAcc':
        if (!isAccCheckRequired) {
          let clearPersonalAcc = valueField.replaceAll(/[-_\s]/g, '').trim();

          if (/^[0-9]{0,}$|^\s*$/.test(clearPersonalAcc)) {
            setPersonalAcc(clearPersonalAcc);
          }

          if (!(/^[0-9]{1,}$/.test(clearPersonalAcc))) {
            isFieldValid = false;
          }

        }
        break;

      default:
        isFieldValid = valueField && valueField.length > 0;
        break;
    }

    if ((isCompletedForm.fields).hasOwnProperty(idField)) {
      setIsCompletedForm({
        type: 'field_change',
        field: {[idField]: isFieldValid}
      });
    }
  }

  function handleSelectTemplate(event) {
    setTemplateId(event.target.value);
  }

  function handleSelectPurpose(event) {
    setPurpose(event.target.value ? event.target.value : 'default');

    if (!event.target.selectedOptions[0].text) {
      setDescription(event.target.selectedOptions[0].text || '');
    }

    if (Object.keys(purposeList).length && purposeList[language] && Object.keys(purposeList[language]).length) {
      setIsCompletedForm({
        type: 'field_change',
        field: {'selectPurpose': event.target.value !== 'default'}
      });
    }

    setPurposeValue(JSON.stringify([{Code: event.target.value, Sum: amount * 100}]));
  }

  function formattedAmount(event) {
    let currentSum = (+((event.target.value).replace(',', '.'))).toFixed(2);

    currentSum = currentSum > 0 ? currentSum : '';
    setAmount(currentSum);
  }

  function getIdsRequiredInputs(template, riid, isAccCheckRequired) {
    let defaultInputs = ['amount', isAccCheckRequired ? 'phoneNumber' : 'description'];
    let templateParams = renderParams(template, riid).map(el => el._attributes.Rid);
    let isPurposeList = Object.keys(purposeList).length && purposeList[language] && Object.keys(purposeList[language]).length;
    let idsInputs, uniqueIdsInputs;

    if (isAccCheckRequired) {
      idsInputs = templateParams.filter(el =>
        !(Object.keys(customerData).includes('' + el) && customerData[el]['_text']) && el !== 'PersAcc'
      );

      if (isPurposeList) {
        idsInputs.push('selectPurpose');
      }
    } else {
        idsInputs = idsRequiredInputsOnRiid;
    }

    idsInputs.push(...defaultInputs);

    uniqueIdsInputs = idsInputs.filter((element, index, arr) =>
      arr.indexOf(element) === index
      && (templateParams.includes('' + element)
        || defaultInputs.includes('' + element)
        || (element === 'selectPurpose' && isPurposeList && template))
      && !(element === 'description' && isRenderDescriptionNotNeeded)
    );

    return uniqueIdsInputs.reduce((targetObj, id) => ({
        ...targetObj,
        [id]: false
      }), {});
  }

  return (
    <React.Fragment>
      <div className='container-create-bill'>
        <form onSubmit={event => {
          if (isCompletedForm.isEnabledButton) {
            actions.createBill.bind(actions)(event);
          } else {
            event.preventDefault();
          }
        }}>
          <fieldset>
            <label htmlFor='selectTemplate'
                   style={template === '' ? {display: 'none'} : {display: 'block'}}>
              {dictionary[language].selectTemplate}
            </label>
            {billTypes[language] !== null && billTypes[language] !== undefined &&
              <select name='selectTemplate'
                      onChange={handleSelectTemplate}
                      className={(billTypes[language].length < 2 && isAccCheckRequired) ? 'no-choice' : ''}
                      disabled={billTypes[language].length < 2 && isAccCheckRequired}
                      defaultValue={billTypes[language][0] ? billTypes[language][0]._attributes.Id : 'default'}>
                <option key='default'
                        disabled={isAccCheckRequired && billTypes[language][0]}
                        hidden={isAccCheckRequired && billTypes[language][0]}
                        value='default'>
                  {dictionary[language].default}
                </option>
                {billTypes[language].map((item) => {
                  return <option key={item._attributes.Id}
                                 value={item._attributes.Id}>
                    {item.Title._text}</option>
                })}
              </select>}
            <legend>{dictionary[language].billSettings}</legend>
            <label htmlFor='description'
                   className={(getRequiredStatus('description') ? 'is-required' : '')}
                   style={isRenderDescriptionNotNeeded ? {display: 'none'} : {display: 'block'}}>
              {dictionary[language].description}
            </label>
            <input name='description' id='description' data-field='description'
                   value={description} onChange={handleChangeForm} required={getRequiredStatus('description')}
                   style={isRenderDescriptionNotNeeded ? {display: 'none'} : {display: 'block'}}
                   placeholder={dictionary[language].descriptionPlaceholder}/>
            <label htmlFor='phoneNumber'
                   className={getRequiredStatus('phoneNumber') ? 'is-required' : ''}>
              {dictionary[language].clientPhoneNumber}
            </label>
            <div className='tip'>{dictionary[language].clientPhoneNumberTip}</div>
            <input name='phoneNumber' className='invisible' value={phoneNumber} readOnly={true}/>
            <InputMask mask='+9 (999) 999 99 99'
                       data-field='phoneNumber'
                       maskChar={null}
                       required={getRequiredStatus('phoneNumber')}
                       id='phoneNumber'
                       placeholder={dictionary[language].clientPhoneNumberPlaceholder}
                       onChange={handleChangeForm}/>
            {renderParams(template, riid)
              .map((param, i) => {
                  const {Rid} = param._attributes;
                  const isRequired = getRequiredStatus(Rid);

                  return <React.Fragment key={Rid}>
                    <label
                      className={(isRequired ? 'is-required' : '') + ((isAccCheckRequired && Rid === 'PersAcc') ? ' invisible' : '')}>{param.Title._text}</label>
                    {(isAccCheckRequired || Rid !== 'PersAcc') ?
                      <input name={'param_val_' + (i + 1)}
                             onChange={handleChangeForm}
                             id={Rid}
                             required={isRequired}
                             defaultValue={!isRequired && customerData[Rid] && customerData[Rid]['_text']
                               ? customerData[Rid]['_text']
                               : (Rid === 'PersAcc' && isAccCheckRequired ? personalAccount : '')}
                             readOnly={(!isRequired && isAccCheckRequired) || Rid === 'PersAcc'}
                             type={Rid === 'PersAcc' ? 'hidden' : ''}
                      />
                      : <InputMask name={'param_val_' + (i + 1)}
                                   id={Rid}
                                   required={isRequired}
                                   mask={personalAccountMask ? personalAccountMask.toString() : ''}
                                   value={personalAcc}
                                   maskChar={null}
                                   data-field='PersAcc'
                                   placeholder={dictionary[language].clientPersonalAccPlaceholder}
                                   onChange={handleChangeForm}
                      />
                    }
                    <input key={'param_name_' + (i + 1)} name={'param_name_' + (i + 1)}
                           defaultValue={Rid} className='invisible'/>
                  </React.Fragment>
                }
              )}
            {template && Object.keys(purposeList).length !== 0 && Boolean(purposeList[language]) && Object.keys(purposeList[language]).length !== 0 && <>
              <label htmlFor='selectPurpose'
                     className={getRequiredStatus('selectPurpose') ? 'is-required' : ''}>{dictionary[language].selectPurpose}</label>
              <select className={purposeList[language].length < 2 ? 'no-choice' : ''}
                      onChange={handleSelectPurpose}
                      disabled={purposeList[language].length < 2}
                      required={getRequiredStatus('selectPurpose')}
                      defaultValue={purposeList[language].length > 1 ? 'default' : purposeList[language][0].Code}>
                <option key='default'
                        disabled={true}
                        hidden={true}
                        value='default'>{dictionary[language].undefined}</option>
                {purposeList[language].map(item => {
                  return <option key={item.Code} value={item.Code}>{item.Title}</option>
                })}
              </select>
              <input
                name={'param_name_' + (template.Params.Param ? (template.Params.Param.length + 1) : '1')}
                defaultValue='SelectedPurposes' className='invisible'/>
              <input
                name={'param_val_' + (template.Params.Param ? (template.Params.Param.length + 1) : '1')}
                defaultValue={purposeValue} className='invisible'/></>}
            {template && Object.keys(purposeList).length !== 0 && Boolean(purposeList[language])
              && Object.keys(purposeList[language]).length !== 0 && purposeList[language].length < 2 && <>
              <input
              name={'param_name_' + (template.Params.Param ? (template.Params.Param.length + 2) : '2')}
              defaultValue='DefaultPurpose' className='invisible'/>
              <input
              name={'param_val_' + (template.Params.Param ? (template.Params.Param.length + 2) : '2')}
              defaultValue={purposeList[language][0].Title} className='invisible'/>
            </>}
          </fieldset>
          <fieldset>
            <label htmlFor='amount' className='is-required'>{dictionary[language].amount}</label>
            <input name='amount'
                   autoComplete='off'
                   id='amount'
                   data-field='amount'
                   placeholder={dictionary[language].amountPlaceholder}
                   pattern='^[0-9]+[.,]?[0-9]{0,2}$'
                   required={true}
                   onChange={handleChangeForm}
                   inputMode='decimal'
                   onBlur={formattedAmount}
                   value={amount}
            />
            <input name='currency_int_code'
                   id='currency_int_code'
                   data-field='currency_int_code'
                   value={currency ? currency : ''}
                   readOnly={true}
                   className='currencyInput'/>
            <input placeholder={Object.keys(currencyList).find(key => currencyList[key] === currency)}
                   id='currencyFieldShown'
                   readOnly={true}/>
          </fieldset>
          <button type='Submit'
                  className={isCompletedForm.isEnabledButton ? '' : 'disableSubmitData'}>{dictionary[language].sendTheBill}</button>
        </form>
      </div>
    </React.Fragment>
  )
});

export default CreateBill

function renderParams(template) {
  if (template) {
    const {Param} = template.Params;

    if (Param) {
      return Array.isArray(Param) ? Param : [Param];
    }
  }

  return [];
}