import {observable, action} from 'mobx';
import AppGlobalStore from './appGlobalStore';

class AppStateStore {

    @observable isUserAuthorized = false;
    @observable showPreloader = false;
    @observable showCancelBillPopUp = false;
    @observable tosterText = null;
    @observable language = null;
    @observable popUpStatus = null;
    @observable lastUpdate = null;
    @observable FromCreateTime = new Date();
    @observable refreshSort = false;
    @observable billListLength = null;
    @observable isPersAccExist = false;
    @observable popUpNewStatus = null;
    @observable isAccCheckRequired = false;
    @observable idsRequiredInputsOnRiid = [];
    @observable isRenderDescriptionNotNeeded = false;
    @observable fixPersAccInfo = null;
    @observable billFilter = 'Active';
    @observable sessionWarningTimer = null;
    @observable emptyPurposeReplacement = null;

    @action setIsUserAuthorized = data => this.isUserAuthorized = data;
    @action setShowPreloader = data => this.showPreloader = data;
    @action setShowCancelBillPopUp = data => this.showCancelBillPopUp = data;
    @action setTosterText = data => this.tosterText = data;
    @action setLanguage = data => this.language = data;
    @action setPopUpStatus = data => this.popUpStatus = data;
    @action clearPopUpSessionWarningTimer = () => clearTimeout(this.sessionWarningTimer);
    @action runPopUpSessionWarningTimer = (time, {warning, buttons}) => {
        this.clearPopUpSessionWarningTimer();
        this.sessionWarningTimer = setTimeout(() => {
            this.setPopUpStatus({
                status: warning,
                buttons
            })
        }, time - ((AppGlobalStore.sessionMaxAge * 0.15) || 15000));
    }
    @action setLastUpdate = (data) => {
        this.lastUpdate = data;
    };
    @action setFromCreateTime = (data) => {
        this.FromCreateTime = data;
    };
    @action setRefreshSort = () => this.refreshSort = !this.refreshSort;
    @action setBillListLength = data => this.billListLength = data;
    @action setIsPersAccExist = data => this.isPersAccExist = data;
    @action setPopUpNewStatus = data => this.popUpNewStatus = data;
    @action setIsAccCheckRequired = data => this.isAccCheckRequired = data;
    @action setIdsRequiredInputsOnRiid = data => this.idsRequiredInputsOnRiid = data;
    @action setIsRenderDescriptionNotNeeded = data => this.isRenderDescriptionNotNeeded = data;
    @action setFixPersAccInfo = data => this.fixPersAccInfo = data;
    @action setBillFilter = data => this.billFilter = data;
    @action setEmptyPurposeReplacement = data => this.emptyPurposeReplacement = data;
}


const appStateStore = new AppStateStore();
export default appStateStore;