import {observable, action} from "mobx";

class AppGlobalStore {

    @observable newBillTimer = null;
    @observable billListTimer = null;
    @observable mobiCashLink = null;
    @observable eInvoicingLink = null;
    @observable eInvoicing = true;
    @observable sessionMaxAge = null;
    @observable dictionary = null;
    @observable billListStartLength = null;
    @observable billListAddLength = null;
    @observable currencyList = null;
    @observable showBanner = false;

    @action setGlobal = data => {
        this.newBillTimer = data.newBillTimer;
        this.billListTimer = data.billListTimer;
        this.mobiCashLink = data.mobiCashLink;
        this.eInvoicingLink = data.eInvoicingLink;
        this.eInvoicing = data.eInvoicing;
        this.sessionMaxAge = data.sessionMaxAge;
        this.billListStartLength = data.billListStartLength;
        this.billListAddLength = data.billListAddLength;
        this.currencyList = data.currencyList;
        this.showBanner = data.showBanner;
    };
    @action setDictionary = data => this.dictionary = data;

}


const appGlobalStore = new AppGlobalStore();
export default appGlobalStore;