import React from 'react';
import DatePicker from 'react-date-picker';
import appStateStore from '../store/appStateStore';
import appGlobalStore from '../store/appGlobalStore';
import RefreshBillListButton from './RefreshBillListButton';
import actions from '../actions';


function DateFilter() {
    const {setFromCreateTime, FromCreateTime, language, setShowPreloader, setBillListLength} = appStateStore;
    const {dictionary, billListStartLength} = appGlobalStore;

    function onChangeHandler(newDate) {
        setShowPreloader(true);
        setBillListLength(billListStartLength);
        setFromCreateTime(newDate ? newDate : new Date());
        return actions.getBillList(false);
    }

    return <>
        <label>{dictionary[language].ondate}</label>
        <div className='data-reload'>
            <DatePicker onChange={onChangeHandler} format='dd.MM.y' calendarIcon='' value={FromCreateTime}/>
            <RefreshBillListButton/>
        </div>
    </>
}

export default DateFilter